import {
  SET_FACILITIES_LIST,
} from '../mutation-types'
import request from '@/libs/request'
import image from '@/libs/image'

const state = {
  completedFacilities: [],
  nearbyFacilities: [],
}

const getters = {
  completedFacilities: (state) => state.completedFacilities,
  nearbyFacilities: (state) => state.nearbyFacilities,
}

const mutations = {
  [SET_FACILITIES_LIST] (state, facilities) {
    state.completedFacilities = facilities.filter(facility => facility.type === 'complete')
    state.nearbyFacilities = facilities.filter(facility => facility.type === 'nearby')
  },
}

const actions = {
  async getFacilitiesList ({ commit }) {
    const { data } = await request.post('/collections/get/facility', {
      fields: {
        facilityName: 1,
        facilityImage: 1,
        facilityType: 1,
      },
    })
    commit(SET_FACILITIES_LIST, data.entries.map(e => ({
      name: e.facilityName,
      thumb: image(e.facilityImage.path),
      type: e.facilityType,
    })))
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
