import {
  SET_OFFICE_DETAIL,
  SET_OFFICE_TYPES,
} from '../mutation-types'
import request from '@/libs/request'
import image from '@/libs/image'

const state = {
  officeDetail: {},
}

const getters = {
  officeDetail: (state, rootState) => ({
    ...state.officeDetail,
    desc: state.officeDetail.desc[rootState.lang],
  }),
  officeTypes: (state) => state.officeTypes,
}

const mutations = {
  [SET_OFFICE_DETAIL] (state, officeDetail) {
    state.officeDetail = officeDetail
  },
  [SET_OFFICE_TYPES] (state, officeTypes) {
    state.officeTypes = officeTypes
  },
}

const actions = {
  async getOfficeDetail ({ commit }) {
    const { data } = await request.post('/singletons/get/OfficeConfig', {
      populate: 1,
    })

    if (!data) {
      throw Error
    }

    commit(SET_OFFICE_DETAIL, {
      desc: {
        en: data.officeDesc,
        id: data.officeDesc_id,
      },
      hero: image(data.officeHeroImage.path),
      brochure: data.officeBrochure,
      '3dLink': data.office3dLink,
    })

    commit(SET_OFFICE_TYPES, data.officeTypes.map(e => ({
      name: e.typeName,
      image: e.typeImage.map(f => image(f.value.path)),
      landArea: e.typeLandArea,
      buildingArea: e.typeBuildingArea,
      floors: e.typeFloors,
    })))
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
