<template>
  <div id="app">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import AOS from 'aos'
import metaInfo from '@/constants/metaInfo'

const Navbar = () => import('@/components/common/Navbar.vue')
const Footer = () => import('@/components/common/Footer.vue')

export default {
  metaInfo,
  components: {
    Navbar,
    Footer,
  },
  mounted () {
    AOS.init({
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    })
  },
}
</script>
