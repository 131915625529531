import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/events-news',
    name: 'events.news',
    component: () => import('@/views/events-news/EventsNews.vue'),
  },
  {
    path: '/news/:slug',
    name: 'news.detail',
    component: () => import('@/views/events-news/News.vue'),
  },
  {
    path: '/event/:slug',
    name: 'event.detail',
    component: () => import('@/views/events-news/Event.vue'),
  },
  {
    path: '/cluster',
    name: 'cluster',
    component: () => import('@/views/cluster/ClusterList.vue'),
  },
  {
    path: '/cluster/:slug',
    name: 'cluster.detail',
    component: () => import('@/views/cluster/ClusterDetail.vue'),
  },
  {
    path: '*',
    name: 'error',
    component: () => import('@/views/Error.vue'),
  },
  {
    path: '/facilities',
    name: 'facilities',
    component: () => import('../views/facilities/Facilities.vue'),
  },
  {
    path: '/office',
    name: 'office',
    component: () => import('../views/Office.vue'),
  },
  {
    path: '/commercial',
    name: 'commercial',
    component: () => import('../views/Commercial.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
