import {
  SET_EVENT_DETAIL,
  SET_EVENT_LIST,
} from '../mutation-types'
import request from '@/libs/request'
import image from '@/libs/image'

const state = {
  eventList: [],
  eventDetail: {},
}

const getters = {
  eventList: (state) => state.eventList,
  eventDetail: (state) => state.eventDetail,
}

const mutations = {
  [SET_EVENT_LIST] (state, eventList) {
    state.eventList = eventList
  },
  [SET_EVENT_DETAIL] (state, eventDetail) {
    state.eventDetail = eventDetail
  },
}

const actions = {
  async getEventList ({ commit }) {
    const { data } = await request.post('/collections/get/events', {
      fields: {
        eventTitle: 1,
        eventThumbnailImage: 1,
        eventDate: 1,
        eventTime: 1,
        eventLocation: 1,
        slug: 1,
      },
      sort: {
        eventDate: 1,
        eventTime: 1,
      },
    })
    commit(SET_EVENT_LIST, data.entries.map(e => ({
      title: e.eventTitle,
      thumb: image(e.eventThumbnailImage.path),
      date: e.eventDate,
      time: e.eventTime,
      location: e.eventLocation,
      slug: e.slug,
    })))
  },
  async getEventDetail ({ commit }, slug) {
    const { data } = await request.post('/collections/get/events', {
      fields: {
        eventTitle: 1,
        eventHeroImage: 1,
        eventDate: 1,
        eventTime: 1,
        eventLocation: 1,
        eventExcerpt: 1,
        eventBody: 1,
        slug: 1,
      },
      filter: {
        slug,
      },
    })

    if (data.entries.length === 0) {
      throw Error
    }

    commit(SET_EVENT_DETAIL, data.entries.map(e => ({
      title: e.eventTitle,
      hero: image(e.eventHeroImage.path),
      date: e.eventDate,
      time: e.eventTime,
      location: e.eventLocation,
      excerpt: e.eventExcerpt,
      body: e.eventBody,
      slug: e.slug,
    }))[0])
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
