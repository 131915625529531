import {
  SET_HOME_HERO,
  SET_HOME_FACILITY_PREVIEW,
  SET_NEWS_PREVIEW,
  SET_PRODUCT_PREVIEW,
  SET_CONTACT,
  SET_YOUTUBE_VIDEO_ID,
  SET_INVESTOR_IMAGE,
} from '../mutation-types'
import request from '@/libs/request'
import image from '@/libs/image'

const state = {
  homeHero: {},
  homeFacilityPreview: {},
  newsPreview: [],
  productPreview: {},
  contactUs: {},
  youtubeEmbedVideoId: '',
  investorImage: [],
}

const getters = {
  homeHero: (state, rootState) => ({
    title: state.homeHero.title[rootState.lang],
    background: state.homeHero.background,
  }),
  homeFacilityPreview: (state, rootState) => ({
    title: state.homeFacilityPreview.title[rootState.lang],
    btnText: state.homeFacilityPreview.btnText[rootState.lang],
    contents: state.homeFacilityPreview.contents[rootState.lang],
  }),
  newsPreview: (state) => state.newsPreview,
  productPreview: (state, rootState) => ({
    contents: state.productPreview.contents[rootState.lang],
    desc: state.productPreview.desc[rootState.lang],
  }),
  contactUs: (state) => state.contactUs,
  youtubeEmbedVideoId: (state) => state.youtubeEmbedVideoId,
  investorImage: (state) => state.investorImage,
}

const mutations = {
  [SET_HOME_HERO] (state, homeHero) {
    state.homeHero = homeHero
  },
  [SET_HOME_FACILITY_PREVIEW] (state, homeFacilityPreview) {
    state.homeFacilityPreview = homeFacilityPreview
  },
  [SET_NEWS_PREVIEW] (state, newsPreview) {
    state.newsPreview = newsPreview
  },
  [SET_PRODUCT_PREVIEW] (state, productPreview) {
    state.productPreview = productPreview
  },
  [SET_CONTACT] (state, contactUs) {
    state.contactUs = contactUs
  },
  [SET_YOUTUBE_VIDEO_ID] (state, youtubeEmbedVideoId) {
    state.youtubeEmbedVideoId = youtubeEmbedVideoId
  },
  [SET_INVESTOR_IMAGE] (state, investorImage) {
    state.investorImage = investorImage
  },
}

const actions = {
  async getHomeConfig ({ commit }) {
    const { data } = await request.get('/singletons/get/HomeConfig')
    commit(SET_HOME_HERO, {
      title: {
        en: data.heroTitle,
        id: data.heroTitle_id,
      },
      background: image(data.heroBackground.path),
    })
    commit(SET_HOME_FACILITY_PREVIEW, {
      title: {
        en: data.facilityPreviewTitle,
        id: data.facilityPreviewTitle_id,
      },
      btnText: {
        en: data.facilityPreviewButtonText,
        id: data.facilityPreviewButtonText_id,
      },
      contents: {
        en: data.facilityPreviewContent.map(e => ({
          title: e.value.title,
          image: image(e.value.image.path),
        })),
        id: data.facilityPreviewContent_id.map(e => ({
          title: e.value.title,
          image: image(e.value.image.path),
        })),
      },
    })
    commit(SET_PRODUCT_PREVIEW, {
      desc: {
        en: data.productPreviewDescription,
        id: data.productPreviewDescription_id,
      },
      contents: {
        en: data.productPreviewType.map(product => ({
          title: product.value.title,
          desc: product.value.desc,
          image: image(product.value.image.path),
          btnText: product.value.btnText,
          btnTarget: product.value.btnTarget,
        })),
        id: data.productPreviewType_id.map(product => ({
          title: product.value.title,
          desc: product.value.desc,
          image: image(product.value.image.path),
          btnText: product.value.btnText,
          btnTarget: product.value.btnTarget,
        })),
      },
    })
    commit(SET_CONTACT, {
      phoneNumber: data.phoneNumber,
      whatsAppNumber: data.whatsAppNumber,
      email: data.email,
    })
    commit(SET_YOUTUBE_VIDEO_ID, data.youtubeEmbedVideoId)
    commit(SET_INVESTOR_IMAGE, data.investorImage.map(e => image(e.value.image.path)))
  },
  async getNewsPreview ({ commit }) {
    const { data } = await request.post('/collections/get/news', {
      fields: {
        newsTitle: 1,
        newsThumbnailImage: 1,
        slug: 1,
      },
      limit: 3,
      sort: {
        _created: -1,
      },
    })
    commit(SET_NEWS_PREVIEW, data.entries.map(e => ({
      title: e.newsTitle,
      thumb: image(e.newsThumbnailImage.path),
      slug: e.slug,
    })))
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
