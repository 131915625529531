import {
  SET_LANG,
} from '../mutation-types'
import Cookies from 'js-cookie'

const state = {
  lang: Cookies.get('i18n_locale') || window.navigator.language.startsWith('id') ? 'id' : 'en',
}

const getters = {
  lang: state => state.lang,
}

const mutations = {
  [SET_LANG] (state, lang) {
    state.lang = lang
  },
}

const actions = {
  setLanguage ({ commit }, lang) {
    commit(SET_LANG, lang)
    Cookies.set('i18n_locale', lang)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
