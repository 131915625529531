export const SET_LANG = 'SET_LANG'
export const SET_HOME_HERO = 'SET_HOME_HERO'
export const SET_HOME_FACILITY_PREVIEW = 'SET_HOME_FACILITY_PREVIEW'
export const SET_NEWS_PREVIEW = 'SET_NEWS_PREVIEW'
export const SET_NEWS_DETAIL = 'SET_NEWS_DETAIL'
export const SET_NEWS_LIST = 'SET_NEWS_LIST'
export const SET_EVENT_DETAIL = 'SET_EVENT_DETAIL'
export const SET_EVENT_LIST = 'SET_EVENT_LIST'
export const SET_CLUSTER_DETAIL = 'SET_CLUSTER_DETAIL'
export const SET_CLUSTER_LIST = 'SET_CLUSTER_LIST'
export const SET_CLUSTER_TYPES = 'SET_CLUSTER_TYPES'
export const SET_PRODUCT_PREVIEW = 'SET_PRODUCTS_PREVIEW'
export const SET_CONTACT = 'SET_CONTACT'
export const SET_YOUTUBE_VIDEO_ID = 'SET_YOUTUBE_VIDEO_ID'
export const SET_INVESTOR_IMAGE = 'SET_INVESTOR_IMAGE'
export const SET_FACILITIES_LIST = 'SET_FACILITIES_LIST'
export const SET_OFFICE_DETAIL = 'SET_OFFICE_DETAIL'
export const SET_OFFICE_TYPES = 'SET_OFFICE_TYPES'
export const SET_COMMERCIAL_DETAIL = 'SET_COMMERCIAL_DETAIL'
export const SET_COMMERCIAL_TYPES = 'SET_COMMERCIAL_TYPES'
