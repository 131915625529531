import axios from 'axios'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

request.defaults.params = {
  token: process.env.VUE_APP_API_TOKEN,
}

export default request
