var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('Navbar'), _c('router-view'), _c('Footer')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }