import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRellax from 'vue-rellax'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './styles/scaffold.less'
import './assets/tailwind.css'
import 'aos/dist/aos.css'
import VueGtag from 'vue-gtag'

Vue.use(VueMeta)
Vue.use(VueRellax)
Vue.use(VueGtag, {
  config: { id: 'UA-179045419-7' },
  includes: [
    { id: 'AW-816018637' },
  ],
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
