import Vue from 'vue'
import Vuex from 'vuex'

import cluster from './modules/cluster'
import events from './modules/events'
import home from './modules/home'
import lang from './modules/lang'
import news from './modules/news'
import facilities from './modules/facilities'
import office from './modules/office'
import commercial from './modules/commercial'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cluster,
    events,
    home,
    lang,
    news,
    facilities,
    office,
    commercial,
  },
})
