import {
  SET_NEWS_DETAIL,
  SET_NEWS_LIST,
} from '../mutation-types'
import request from '@/libs/request'
import image from '@/libs/image'

const PER_PAGE = window.innerWidth < 768 ? 7 : 12

const state = {
  newsDetail: {},
  newsList: {},
}

const getters = {
  newsDetail: (state) => state.newsDetail,
  newsList: (state) => state.newsList.entries,
  newsListMaxPage: (state) => Math.ceil(state.newsList.total / PER_PAGE),
}

const mutations = {
  [SET_NEWS_DETAIL] (state, newsDetail) {
    state.newsDetail = newsDetail
  },
  [SET_NEWS_LIST] (state, newsList) {
    state.newsList = newsList
  },
}

const actions = {
  async getNewsDetail ({ commit }, slug) {
    const { data } = await request.post('/collections/get/news', {
      fields: {
        newsTitle: 1,
        newsHeroImage: 1,
        newsExcerpt: 1,
        newsBody: 1,
        slug: 1,
      },
      filter: {
        slug,
      },
    })

    if (data.entries.length === 0) {
      throw Error
    }

    commit(SET_NEWS_DETAIL, data.entries.map(e => ({
      title: e.newsTitle,
      hero: image(e.newsHeroImage.path),
      excerpt: e.newsExcerpt,
      body: e.newsBody,
      slug: e.slug,
    }))[0])
  },
  async getNewsList ({ commit }, page = 1) {
    const { data } = await request.post('/collections/get/news', {
      fields: {
        newsTitle: 1,
        newsThumbnailImage: 1,
        slug: 1,
      },
      limit: PER_PAGE,
      skip: (page - 1) * PER_PAGE,
      sort: {
        _created: -1,
      },
    })
    commit(SET_NEWS_LIST, {
      entries: data.entries.map(e => ({
        title: e.newsTitle,
        thumb: image(e.newsThumbnailImage.path),
        slug: e.slug,
      })),
      total: data.total,
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
