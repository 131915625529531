import {
  SET_CLUSTER_DETAIL,
  SET_CLUSTER_LIST,
  SET_CLUSTER_TYPES,
} from '../mutation-types'
import request from '@/libs/request'
import image from '@/libs/image'

const state = {
  clusterList: [],
  clusterDetail: {},
  clusterTypes: [],
}

const getters = {
  clusterList: (state) => state.clusterList,
  clusterDetail: (state, rootState) => ({
    ...state.clusterDetail,
    buildingArea: state.clusterDetail.buildingArea[rootState.lang],
  }),
  clusterTypes: (state) => state.clusterTypes,
}

const mutations = {
  [SET_CLUSTER_LIST] (state, clusterList) {
    state.clusterList = clusterList
  },
  [SET_CLUSTER_DETAIL] (state, clusterDetail) {
    state.clusterDetail = clusterDetail
  },
  [SET_CLUSTER_TYPES] (state, clusterTypes) {
    state.clusterTypes = clusterTypes
  },
}

const actions = {
  async getClusterList ({ commit }) {
    const { data } = await request.post('/collections/get/cluster', {
      fields: {
        clusterName: 1,
        clusterThumbImage: 1,
        slug: 1,
      },
    })
    commit(SET_CLUSTER_LIST, data.entries.map(e => ({
      name: e.clusterName,
      thumb: image(e.clusterThumbImage.path),
      slug: e.slug,
    })))
  },
  async getClusterDetail ({ commit }, slug) {
    const { data } = await request.post('/collections/get/cluster', {
      fields: {
        clusterName: 1,
        clusterHeroImage: 1,
        clusterBrochure: 1,
        cluster3dLink: 1,
        clusterBedrooms: 1,
        clusterBathrooms: 1,
        clusterBuildingArea: 1,
        clusterTypes: 1,
        slug: 1,
      },
      filter: {
        slug,
      },
      populate: 1,
    })

    if (data.entries.length === 0) {
      throw Error
    }

    commit(SET_CLUSTER_DETAIL, data.entries.map(e => ({
      name: e.clusterName,
      hero: image(e.clusterHeroImage.path),
      brochure: e.clusterBrochure ? image(e.clusterBrochure) : '',
      '3dLink': e.cluster3dLink,
      bedrooms: e.clusterBedrooms,
      bathrooms: e.clusterBathrooms,
      buildingArea: {
        en: e.clusterBuildingArea,
        id: e.clusterBuildingArea_id,
      },
      slug: e.slug,
    }))[0])
    commit(SET_CLUSTER_TYPES, data.entries[0]?.clusterTypes?.map(e => ({
      name: e.typeName,
      image: e.typeImage.map(f => image(f.value.path)),
      landArea: e.typeLandArea,
      buildingArea: e.typeBuildingArea,
      floors: e.typeFloors,
      bedrooms: e.typeBedrooms,
      showers: e.typeShowers,
      carParks: e.typeCarParks,
    })))
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
