import {
  SET_COMMERCIAL_DETAIL,
  SET_COMMERCIAL_TYPES,
} from '../mutation-types'
import request from '@/libs/request'
import image from '@/libs/image'

const state = {
  commercialDetail: {},
}

const getters = {
  commercialDetail: (state, rootState) => ({
    ...state.commercialDetail,
    desc: state.commercialDetail.desc[rootState.lang],
  }),
  commercialTypes: (state) => state.commercialTypes,
}

const mutations = {
  [SET_COMMERCIAL_DETAIL] (state, commercialDetail) {
    state.commercialDetail = commercialDetail
  },
  [SET_COMMERCIAL_TYPES] (state, commercialTypes) {
    state.commercialTypes = commercialTypes
  },
}

const actions = {
  async getCommercialDetail ({ commit }) {
    const { data } = await request.post('/singletons/get/CommercialConfig', {
      populate: 1,
    })

    if (!data) {
      throw Error
    }

    commit(SET_COMMERCIAL_DETAIL, {
      desc: {
        en: data.commercialDesc,
        id: data.commercialDesc_id,
      },
      hero: image(data.commercialHeroImage.path),
      brochure: data.commercialBrochure,
      '3dLink': data.commercial3dLink,
    })

    commit(SET_COMMERCIAL_TYPES, data.commercialTypes.map(e => ({
      name: e.typeName,
      image: e.typeImage.map(f => image(f.value.path)),
      landArea: e.typeLandArea,
      buildingArea: e.typeBuildingArea,
      floors: e.typeFloors,
      bedrooms: e.typeBedrooms,
      showers: e.typeShowers,
      carParks: e.typeCarParks,
    })))
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
